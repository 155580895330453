@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~antd/dist/antd.css';
body {
  background :#E7ECEF;
}

.ant-table-tbody{
  padding: 0 !important;
}
.ant-table-thead > tr > th {
  /* padding: 0px 16px !important; */
  background-color: #213e97 !important;
  color: #fff !important;
}
.ant-table-tbody > tr > td{
  padding: 0px 10px !important;
}